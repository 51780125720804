<template>
    <div class="jjgl">
        <div class="c-left">
            <div class="border"></div>
            <p class="fund">基金管理</p>
            <p class="txt">
                金融市场日新月异，量化分析异军突起，面对海外资管机构陆续进场，谁能充分利用专业的第三方增强数据与组合管理工具，提升投资组合的收益-风险绩效比，谁将抢占金融博弈的先机。
            </p>
            <p class="txt">
                 无论您的投资策略是主动交易、指数增强还是指数跟踪，无论您管理的资产是股票，债券，可转债、期货、基金或其他衍生品，智君科技均可为您提供全方位的领先的解决方案。帮助您进行专业的定价试算、情景分析；组合优化、风险预测、风险约束；绩效归因、风险分解、压力测试，覆盖了投前-投中-投后的方方面面，降低运营成本，控制组合风险，搏击稳健收益。
            </p>
            <p class="txt">智君科技拥有华尔街的前沿的研究成果，依托先进的<span class="mark">多资产多因子模型、金融量化算法</span>和精准可靠的<span class="mark">大数据处理，</span>助你独占鳌头，始终站在金融科技的前沿。</p>
            <p class="solve">
                <span>解决方案：</span>
                <a>
                    <router-link to="/attributionperform">
                        绩效归因<SvgIcon icon-class="圆箭头" style="padding:0 3px" />
                    </router-link>
                </a>
                <a style="margin:0 18px">
                    <router-link to="/riskmeasurement">
                        风险计量<SvgIcon icon-class="圆箭头" style="padding:0 3px" />
                    </router-link>
                </a>
                <a>
                    <router-link to="/combinatorialoptimization">
                        组合优化<SvgIcon icon-class="圆箭头" style="padding:0 3px" />
                    </router-link>
                </a>
            </p>
        </div>
        <div class="c-right">
            <img src="../../assets/customer/基金管理.png" alt="" style="width:474px;height:317px">
        </div>
    </div>
</template>

<script>
import SvgIcon from '@/components/SvgIcon'

export default {
    components:  {
        SvgIcon
    },
    data() {
        return {
            
        }
    }
}
</script>

<style lang="less" scoped>
.jjgl{
    width:1340px;
    height: 317px;
    margin:0 auto;
    // background:yellow;
    padding:79px 0 90px 0;
    overflow-x: hidden;
    .c-left{
        height:317px;
        // background:red;
        // width:15%;
        float:left;
        margin-left:5.4%;
        .border{
            width:130px;
            height:8px;
            background:#2E5082;
        }
        .fund{
            // width:308px;
            height:40px;
            font-size:32px;
            font-family:PingFangSC-Semibold,PingFang SC;
            font-weight:600;
            color:rgba(51,51,51,1);
            line-height:32px;
            margin:15px 0;
            text-align:left;
        }
        .txt{
            width:658px;
            // height:80px;
            // float:left;
            font-size:14px;
            font-family:PingFangSC-Regular,PingFang SC;
            font-weight:400;
            color:rgba(51,51,51,1);
            line-height:20px;
            text-indent: 28px;
            text-align:left;
            // margin-bottom:74px;
            
        }
        .mark{
                color:#2E5082;
                font-weight:bold;
            }
        .solve{
            // width:180px;
            height:20px;
            float:left;
            font-size:14px;
            font-family:PingFangSC-Regular,PingFang SC;
            // font-weight:400;
            color:rgba(51,51,51,1);
            line-height:20px;
            margin-top:19px;
            padding:0;
            a{
                text-decoration: none;
                color:#476CCB;
            }
        }

    }
    .c-right{
        width:474px;
        height:317px;
        float:right;
        margin-right:70px;
    }
    
}
</style>